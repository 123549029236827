import './App.css';
// import axios from 'axios';
import { Home } from './components/home/index.js'
import { Gallery } from './components/gallery/index.js'
import { Catalog } from './components/catalog/index.js'
import { Services } from './components/services/index.js'
import { Contact } from './components/contact/index.js'
import { Routes, Route } from 'react-router-dom';
import { Investor } from './components/investor/index.js';
import { Details } from './components/details/index.js';
import { Cart } from './components/cart';
import { Order } from './components/order';
import { Rules } from './components/rules';
import { Notfound } from './components/notfound';

import ReactMarkdown from 'react-markdown'
import axios from 'axios';
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import { url } from "./api";
import { useState, useEffect } from 'react';

function Promo({promo}) {
  const [timeLeft, setTimeLeft] = useState({});
  useEffect(() => {
    setCounter()
    const timer = setInterval(() => {
      setCounter()
    }, 1000);

    return () => clearInterval(timer);
  }, [promo.time_to]);

  function setCounter(){
    const difference = +new Date(promo.time_to) - +new Date();
    let timeLeftX = {};
    timeLeftX = {
      hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0'),
      minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, '0'),
      seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, '0'),
      difference: difference
    }
    if(timeLeftX <= 0){
      setTimeLeft({
        hours: "00",
        minutes: "00",
        seconds: "00",
      })
    }
    setTimeLeft(timeLeftX)
  }

  return (
    <a href={promo.link} className={`${promo.visible ? 'visible' : ''} promo`} style={{backgroundColor: promo.color}}>
      <div className="promo-title">{promo.title}</div>
      <div className="promo-description"><ReactMarkdown remarkPlugins={remarkGfm} rehypePlugins={[rehypeRaw]} escapeHtml={false}>{promo.description}</ReactMarkdown></div>
      
      {
        timeLeft.difference > 0 ? (
          <div className="promo-counter">
            <span>Koniec za:</span>
            <span className="promo-counter-big">{timeLeft.hours}</span>
            <span className="promo-counter-small">godz.</span>
            <span className="promo-counter-big">{timeLeft.minutes}</span>
            <span className="promo-counter-small">min.</span>
            <span className="promo-counter-big">{timeLeft.seconds}</span>
            <span className="promo-counter-small">sek.</span>
          </div>
        )
        :
        ''
      }
      <div class="promo-btn">SPRAWDŹ</div>
    </a>
  )
}

function App() {
  const [ promo, setPromo ] = useState({})
  useEffect(() => {
    axios.get(`${url}/natalia/promos/`)
    .then((res) => {
      let prom = res.data[0]
      setPromo(prom)
    })
    .catch((err) => {
      console.error('Error:', err);
    });
  }, []);
  return (
    <div className="App">
      {promo && promo.visible ?   
        <Promo promo={promo}></Promo>
        :
        ""
      }
      <Routes>
        <Route path="/" element={< Home/> } />
        <Route path="/galeria/" element={< Gallery/> } />
        <Route path="/katalog/" element={< Catalog/> } />
        <Route path="/katalog/:category" element={< Catalog/> } />
        <Route path="/projekt/:id" element={< Details/> } />
        <Route path="/uslugi/:id" element={< Services/> } />
        <Route path="/uslugi/" element={< Services/> } />
        <Route path="/kontakt/" element={<Contact/> } />
        <Route path="/kacikinwestora/" element={<Investor/> } />
        <Route path="/koszyk/:id" element={<Cart/> } />
        <Route path="/zamowienie/:id" element={<Order/> } />
        <Route path="/regulamin/" element={<Rules/> } />
        <Route path="/*" element={<Notfound/> } />
      </Routes>
    </div>
  );
}

export default App;
