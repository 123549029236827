import { Footer } from "../footer";
import { Navbar } from "../navbar";
import './style.css'

export function Notfound(){
    return(
        <div className="notfound">
            <div className="container">
                <Navbar></Navbar>
                <img alt="Grafika dom" className="bg-house" src={`${process.env.PUBLIC_URL}/assets/img/bg-house.png`}/>
                <div className="notfound-text">Błąd 404, nie znaleziono strony</div>
            </div>
            <Footer></Footer>
        </div>
    )
}