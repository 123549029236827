import './slider.css'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

function Slide({slide}){
    let bg = slide.project.images.filter((img) => img.isThumbnail === true)[0].image
    return(
        <div className="slide">
            <div className="slide-bg">
                <img alt="Obraz domu" src={bg}/>
            </div>
            <div className="slide-info">
                <h1 className='slide-title'>{slide.project.name}</h1>
                <p className="slide-description">{slide.project.small_description}</p>
                <div className="slide-btns">
                    <Link reloadDocument to={`/projekt/${slide.project.name}`}>
                        <button className="slide-btn">Wyświetl projekt</button>
                    </Link>
                    <Link reloadDocument to="/katalog/">
                        <button className="slide-btn btn-outline">Zobacz inne
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="7.5" stroke="white"/>
                                <path d="M7 5L10 8.42857L7 11" stroke="white"/>
                            </svg>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export function Slider({slides}){
    const [sliderCountdown, setSliderCountdown] = useState(10)
    const [style, setStyle] = useState({})
    const [choosen, chooseSlide] = useState(0)
    const changeSlide = (back) => {
        setSliderCountdown(10)
        let x = 0
        if(back){
            if(choosen === 0) x = slides.length - 1
            else x = choosen - 1
        }
        else{
            if(choosen === slides.length - 1) x = 0
            else x = choosen + 1
        }
        chooseSlide(x)
        let newstyle = {
            margin: `0 0 0 ${-(x * 100)}%`
        }
        setStyle({...newstyle})
    }
    useEffect(() => {
        if(sliderCountdown === 0){
            changeSlide(false)
        }
        const timer = setTimeout(() => {
            let newCountdown = sliderCountdown - 1
            setSliderCountdown(newCountdown)
        }, 1000)
        return () => clearTimeout(timer)
    }, [sliderCountdown])
    return(
        <div className="slider">
            <div className="slider-arrows">
                <div className="slider-arrow" onClick={() => {changeSlide(true)}}>
                    <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 2L3 12L13 22" stroke="white" stroke-width="4"/>
                    </svg>
                </div>
                <div className="slider-arrow" onClick={() => {changeSlide(false)}}>
                    <svg width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 22L12 12L2 2" stroke="white" stroke-width="4"/>
                    </svg>
                </div>
            </div>
            <div className="slider-overlay"></div>
            <div className="slides" style={style}>
                {slides.map((slide) => <Slide slide={slide}></Slide>)}
            </div>
            <div className="slides-dots">
                {slides.map((slide, index) => <div className={`slide-dot ${index === choosen ? 'active' : ''}`}></div>)}
            </div>
        </div>
    )
}