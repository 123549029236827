import './style.css'
import { Navbar } from '../navbar/index.js'
import { Footer } from '../footer/index.js'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { url } from '../../api'

function Question({question, id}){
    const params = useParams();
    const [ expanded, Expand ] = useState(params.id == id + 1 ? true : false)
    return(
        <div className='faq-question services-question'>
            <div className='faq-bookmark'></div>
            <div className="faq-content">
                <div className='faq-question-header'>
                    <h2 className='faq-question-title'>{question.question}</h2>
                    <div className="faq-question-button" onClick={() => Expand(!expanded)}>
                        {expanded ?
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 7L6 2L1 7" stroke="black" stroke-width="2"/>
                            </svg>
                            :
                            <svg className="button-icon-notexpanded" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6 6L11 1" stroke="black" stroke-width="2"/>
                            </svg>
                        }
                    </div>
                </div>
                <div className={`faq-question-desc ${expanded ? "faq-question-desc-expanded" : ""}`}>
                    <p>
                        {/* {question.answer} */}
                        <ul>
                            {question.answer.split('\r\n').map((x) => (
                                <li>{x}</li>
                            ))}
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    )
}

export function Services(){
    const [questions, setQuestions] = useState([])
    useEffect(() => {
        document.title = 'Projekty Gebala - Usługi';
        axios.get(`${url}/core/faq/`)
        .then((res) => {
            let x = res.data.filter((x) => x.question_type === 'services')
            setQuestions([...x])
            console.log(x)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
    }, []);
    return(
        <div className="services">
            <div className="container">
                <img alt="Grafika dom" className="bg-house" src={`${process.env.PUBLIC_URL}/assets/img/bg-house.png`}/>
                <Navbar></Navbar>
                <h1>Czym się zajmujemy?</h1>
                {questions.map((question, index) => <Question question={question} id={index}></Question>)}
            </div>
            <Footer></Footer>
        </div>
    )
}