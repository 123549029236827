import './faq.css'
import { useState } from 'react'

function Question({question}){
    const [ expanded, Expand ] = useState(false)
    return(
        <div className='faq-question'>
            <div className='faq-question-header'>
                <h2 className='faq-question-title'>{question.question}</h2>
                <div className="faq-question-button" onClick={() => Expand(!expanded)}>
                    {expanded ?
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 7L6 2L1 7" stroke="black" stroke-width="2"/>
                        </svg>
                        :
                        <svg className="button-icon-notexpanded" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L6 6L11 1" stroke="black" stroke-width="2"/>
                        </svg>
                    }
                </div>
            </div>
            <div className={`faq-question-desc ${expanded ? "faq-question-desc-expanded" : ""}`}>
                <p>
                    {question.answer}
                </p>
            </div>
        </div>
    )
}

export function Faq({faq}){
    // const [faq, setFaq] = useState([
    //     {title: 'Przykładowe pytanie nr 1', description: "Gwarantujemy uzyskanie pozwolenia na budowę domu w 100% bo jesteśmy najlepszą firmą i tyle 😎. Nic dodać nic ująć. Oczywiście to totalnie testowy tekst, więc proszę traktować jako wypełniacz. Pozdrawiam!!"},
    //     {title: 'Przykładowe pytanie nr 1', description: "Gwarantujemy uzyskanie pozwolenia na budowę domu w 100% bo jesteśmy najlepszą firmą i tyle 😎. Nic dodać nic ująć. Oczywiście to totalnie testowy tekst, więc proszę traktować jako wypełniacz. Pozdrawiam!!"},
    //     {title: 'Przykładowe pytanie nr 1', description: "Gwarantujemy uzyskanie pozwolenia na budowę domu w 100% bo jesteśmy najlepszą firmą i tyle 😎. Nic dodać nic ująć. Oczywiście to totalnie testowy tekst, więc proszę traktować jako wypełniacz. Pozdrawiam!!"},
    //     {title: 'Przykładowe pytanie nr 1', description: "Gwarantujemy uzyskanie pozwolenia na budowę domu w 100% bo jesteśmy najlepszą firmą i tyle 😎. Nic dodać nic ująć. Oczywiście to totalnie testowy tekst, więc proszę traktować jako wypełniacz. Pozdrawiam!!"},
    //     {title: 'Przykładowe pytanie nr 1', description: "Gwarantujemy uzyskanie pozwolenia na budowę domu w 100% bo jesteśmy najlepszą firmą i tyle 😎. Nic dodać nic ująć. Oczywiście to totalnie testowy tekst, więc proszę traktować jako wypełniacz. Pozdrawiam!!"},
    // ])

    return(
        <div className="faq">
            <h1>Najczęściej zadawane pytania</h1>
            {faq.map((question) => <Question question={question}></Question>)}
        </div>
    )
}