import { Link } from 'react-router-dom'
import './tiles.css'
import { useState } from 'react'

export function Tile({tile}){
    return(
        <div className="tile">
            <div className='tile-icon'>
                <img alt="Ikona" src={tile.img}/>
            </div>
            <h2 className="tile-title">{tile.title}</h2>
            <Link reloadDocument to={`/uslugi/${tile.href}`}>
                <button className='tile-btn'><span>Dowiedz się więcej</span>
                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L4 4.42857L1 7" stroke="white"/>
                    </svg>
                </button>
            </Link>
        </div>
    )
}

export function Tiles(){
    const [ tiles, setTiles ] = useState([
        { title: "Projektowanie domów", img: `${process.env.PUBLIC_URL}/assets/img/BHf3jRH.webp`, href: "1" },
        { title: "Prowadzenie budowy", img: `${process.env.PUBLIC_URL}/assets/img/g3QfuJo.webp`, href: "2" },
        { title: "Usługi geodezyjne", img: `${process.env.PUBLIC_URL}/assets/img/m55vTdg.webp`, href: "3" },
        { title: "Świadectwa energetyczne", img: `${process.env.PUBLIC_URL}/assets/img/qBYBWF6.webp`, href: "4" }
        
    ])
    return(
        <div className="tiles">
            {tiles.map((tile) => <Tile tile={tile}></Tile>)}
        </div>
    )
}