import { Footer } from "../footer";
import { Navbar } from "../navbar";
import { useEffect, useState } from "react";

import './style.css'

import axios from "axios";

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import { Loader } from "../loader";
import { url } from "../../api";

export function Rules(){
    const [ privacy, setPrivacy ] = useState("")
    const [ terms, setTerms ] = useState("")
    const [ loaded, setLoaded ] = useState(false)
    useEffect(() => {
        document.title = 'Projekty Gebala - Regulamin i kącik inwestora';
        axios.get(`${url}/core/legal-documents/`)
        .then((res) => {
            let xterms = res.data.filter(item => item.doc_type === "terms")[0]
            let xprivacy = res.data.filter(item => item.doc_type === "privacy")[0]
            setTerms(xterms.content)
            setPrivacy(xprivacy.content)
            setLoaded(true)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
    }, []);
    return(
        <div className="rules">
            <div className="container">
                <Navbar></Navbar>
                <img alt="Grafika dom" className="bg-house" src={`${process.env.PUBLIC_URL}/assets/img/bg-house.png`}/>
                {loaded ?  
                    <div className="rules-container">
                        <h1>Regulamin</h1>
                        <ReactMarkdown remarkPlugins={remarkGfm} rehypePlugins={[rehypeRaw]} escapeHtml={false}>{terms}</ReactMarkdown>
                        <h1>Polityka prywatności</h1>
                        <ReactMarkdown remarkPlugins={remarkGfm} rehypePlugins={[rehypeRaw]} escapeHtml={false}>{privacy}</ReactMarkdown>
                    </div>
                    :
                    <Loader></Loader>
                }
            </div>
            <Footer></Footer>
        </div>
    )
}
