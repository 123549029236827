import { Link } from "react-router-dom";
import { Footer } from "../footer";
import { Navbar } from "../navbar";
import { useEffect, useState } from "react";

import './style.css'

import axios from "axios";

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import { Loader } from "../loader";
import { url } from "../../api";

export function Investor(){
    const [ investor, setInvestor ] = useState("")
    const [ loaded, setLoaded ] = useState(false)
    useEffect(() => {
        document.title = 'Projekty Gebala - Kącik inwestora';
        axios.get(`${url}/core/legal-documents/`)
        .then((res) => {
            let x = res.data.filter(item => item.doc_type === "investor")[0]
            setInvestor(x.content)
            setLoaded(true)
        })
        .catch((err) => {
            console.error('Error:', err);
        });
    }, []);
    return(
        <div className="investor">
            <div className="container">
                <Navbar></Navbar>
                <img alt="Grafika dom" className="bg-house" src={`${process.env.PUBLIC_URL}/assets/img/bg-house.png`}/>
                {loaded ? 
                    <>                    
                        <h1>Kącik inwestora</h1>
                        <div className="investor-content">
                            <ReactMarkdown remarkPlugins={remarkGfm} rehypePlugins={[rehypeRaw]} escapeHtml={false}>{investor}</ReactMarkdown>
                        </div>
                    </>
                    :
                    <Loader></Loader>
                }
            </div>
            <Footer></Footer>
        </div>
    )
}