import './about.css'
import { useState } from 'react'

export function About({about}){
    const [ imgs, setImgs ] = useState([
        {img: `${process.env.PUBLIC_URL}/assets/img/budynek.webp`, alt: "Obraz przedstawiający budynek"},
        {img: `${process.env.PUBLIC_URL}/assets/img/architekt.webp`, alt: "Obraz przedstawiający architekta"},
    ])
    return(
        <div className="about">
            <div className="about-imgs">
                <div className="about-img"><img alt={imgs[0].alt} src={imgs[0].img}/></div>
                <div className="about-img wider"><img alt={imgs[0].alt} src={imgs[1].img}/></div>
            </div>
            <div className="about-info">
                <h1>{about.title}</h1>
                <p className="about-description">{about.description}</p>
            </div>
        </div>
    )
}